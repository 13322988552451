@import '../../../../styles/core.css';

.wrapper {
  max-width: 1208px;
  margin-left: auto;
  margin-right: auto;

  & main {
    @mixin container;

    margin: 46px 0 21px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: justify;
    color: var(--app-bg-color);

    @mixin desktop {
      margin: 100px 0;
    }

    & .illustration {
      margin: 0 19px;

      @mixin mobile {
        width: calc(100% - 2 * 19px);
      }
    }

    & .button {
      @mixin desktop {
        padding: 7.5px 20px;
      }
    }
  }
}
