@import '../../../styles/styles.css';

.privacy-policies-page {
  @mixin legal-content;

  & table {
    border-collapse: collapse;
  }

  & td,
  & th {
    border: 1px solid;
    padding: 5px;
    vertical-align: top;
  }
}
