@import '../../../styles/core.css';

.not-found-page {
  & .illustration {
    & + .title {
      margin-top: 35px;
    }
  }

  & .title {
    & strong {
      font-size: 21px;

      @mixin desktop {
        font-size: 28px;
      }

      color: var(--corporate-primary-color);
    }

    & + .description {
      @mixin desktop {
        margin-top: 33px;
      }
    }
  }

  & .description {
    letter-spacing: normal;
    text-align: justify;
    line-height: 1.47;

    & + .button {
      margin-top: 33px;
    }

    @mixin desktop {
      text-align: center;
    }
  }

  & .button {
    width: 100%;

    @mixin desktop {
      width: unset;
      margin-top: 100px;
    }
  }
}
