@import '../../../../styles/core.css';

.app-header {
  @mixin container;

  & .logo-panel {
    @mixin mobile {
      margin-top: 16px;
      display: flex;
      align-items: center;
    }

    @mixin desktop {
      margin-top: 50px;
    }
  }

  & .logo {
    width: 77px;
    height: 34px;
    display: inline-block;

    @mixin desktop {
      width: 131px;
      height: 59px;
    }
  }
}
