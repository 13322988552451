@custom-media --breakpoint-mobile (width < 1024px);
@custom-media --breakpoint-desktop (width >= 1024px);

@define-mixin mobile {
  @media (--breakpoint-mobile) {
    @mixin-content;
  }
}

@define-mixin desktop {
  @media (--breakpoint-desktop) {
    @mixin-content;
  }
}

@define-mixin legal-content {
  & a {
    color: var(--link-color);
  }

  & h1 {
    text-align: left;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 24px 0;

    @mixin desktop {
      margin: 44px 0;
    }
  }

  & h2 {
    font-stretch: normal;
    font-style: normal;
    line-height: 0.97;
    letter-spacing: normal;
    margin: 24px 0;

    @mixin desktop {
      font-size: 34px;
      margin: 44px 0;
    }
  }
}

@define-mixin container {
  @mixin mobile {
    padding-left: 21px;
    padding-right: 21px;
    box-sizing: border-box;
  }
}
