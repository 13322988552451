body {
  margin: 0;
  font-family: var(--font-family), var(--font-family-fallback);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: var(--font-weight-regular);

  @mixin desktop {
    font-size: 21px;
  }
}

h1 {
  font-size: 30px;
  letter-spacing: -0.31px;
  line-height: normal;
  color: #21212c;

  @mixin desktop {
    letter-spacing: normal;
    font-size: 40px;
  }
}

h2 {
  font-size: 16px;
  letter-spacing: normal;
  line-height: 1.38;
  color: #272140;

  @mixin desktop {
    font-size: 24px;
  }
}

h1,
h2,
h3,
strong,
input[type='text'] {
  font-weight: var(--font-weight-bold);
}

input[type='text'] {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

button {
  font-weight: var(--font-weight-medium);
}

sup {
  vertical-align: baseline;
}

a {
  text-decoration: none;
}
