@import '../../../../styles/core.css';

.button {
  box-sizing: border-box;
  display: inline-block;
  padding: 7.5px 6px;
  font-size: 16px;
  border-radius: 7px;
  font-stretch: normal;
  line-height: 1.81;
  letter-spacing: normal;
  cursor: pointer;

  @mixin desktop {
    padding: 7.5px 20px;
  }

  &.button--plain {
    border: solid 1px transparent;
    color: var(--primary-opposite-color);

    &.button--primary {
      background-color: var(--primary-color);
    }
  }

  &.button--plain:hover,
  &.button--plain:focus,
  &.button--plain:active {
    background-color: var(--primary-opposite-color);

    &.button--primary {
      border: solid 1px var(--primary-color);
      color: var(--primary-color);
      outline-color: var(--primary-color);
    }
  }

  &.button--plain:disabled {
    background-color: var(--disabled-color);

    &.button--primary {
      border: 1px solid transparent;
      color: var(--primary-opposite-color);
    }
  }
}
