@font-face {
  font-family: exo2;
  src: local('Exo2'), local('Exo2-Regular'),
    url(~fonts/Exo2-Regular.ttf) format('ttf');
  font-style: normal;
  font-display: swap;
  font-weight: normal;
}

@font-face {
  font-family: exo2;
  src: local('Exo2'), local('Exo2-Bold'),
    url(~fonts/Exo2-Bold.ttf) format('ttf');
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: exo2;
  src: local('Exo2'), local('Exo2-Light'),
    url(~fonts/Exo2-Light.ttf) format('ttf');
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: exo2;
  src: local('Exo2'), local('Exo2-Medium'),
    url(~fonts/Exo2-Medium.ttf) format('ttf');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}
