@import '../../../../styles/core.css';

.dead-end-wrapper {
  width: 100%;
  max-width: 1208px;
  margin-left: auto;
  margin-right: auto;

  & main {
    @mixin container;

    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;

    & .illustration {
      margin: 0 19px;

      @mixin mobile {
        width: calc(100% - 2 * 19px);
      }
    }

    & .button {
      @mixin desktop {
        padding: 7.5px 20px;
      }
    }
  }
}

.flex-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
