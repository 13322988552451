@import '../../../styles/core.css';

.otp-mail-not-found-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @mixin desktop {
    flex-flow: row nowrap;
    margin-top: -70px;
  }

  & .text-container {
    flex: 1 0 auto;

    & h1 {
      font-size: 26px;
      margin-bottom: 25px;

      @mixin desktop {
        font-size: 35px;
        margin-bottom: 75px;
      }
    }

    & h2 {
      font-size: 17px;

      @mixin desktop {
        font-size: 22px;
      }
    }

    & .highlight {
      color: var(--corporate-primary-color);
    }

    & .subtitle {
      margin-bottom: 30px;
      font-size: 16px;

      @mixin desktop {
        font-size: 20px;
        margin-bottom: 45px;
      }
    }
  }

  & .logo-container {
    width: 55%;
    display: flex;
    justify-content: center;
  }

  & .logo {
    width: inherit;
    margin-top: 60px;
    min-width: 320px;

    @mixin desktop {
      margin: 0 70px;
    }
  }

  & .form-container {
    display: flex;
    column-gap: 15px;
    text-decoration: none;
    color: var(--app-bg-color);
    align-items: center;

    &:hover .arrow {
      transform: translateX(15px);
      transition: transform 0.2s;
    }

    & .label {
      flex: 0 1 auto;
      font-size: 16px;
    }

    & .arrow {
      flex: 1 0 50px;
      max-width: 50px;
      fill: var(--corporate-primary-color);
      transform: translateX(0);
      transition: transform 0.2s;
    }
  }
}
